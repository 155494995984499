@use "sass/color";
@use "sass/size";
@use "sass/font";
@use "sass/mixin";

* {
    margin: 0;
    font-family: font.$text;
}

body,
html,
#root {
    width: 100%;
    height: 100vh;
    background-color: color.$white;
    font-size: clamp(1.8mm, 0.6vw, 2.5mm);
}

body {
    // wallet selector style override
    --wallet-selector-content-bg: #{color.$black};
}

a {
    color: darken(color.$blue, 30%);
    text-decoration: none;
    cursor: pointer;
}

button {
    padding: 0.5 * size.$gap 2 * size.$gap;
    background-color: color.$light;
    border: none;
    color: color.$text;
    font-family: font.$text;
    font-size: size.$large-text;
    cursor: pointer;
}

.MuiCheckbox-root {
    .MuiSvgIcon-root {
        width: size.$large-text;
        height: size.$large-text;
    }
}

.MuiTextField-root {
    .MuiInputLabel-root,
    .MuiInputBase-root {
        font-size: size.$text;

        input {
            padding: calc(size.$gap * 0.4) calc(size.$gap * 0.6);
            font-size: size.$text;
        }

        fieldset {
            border-radius: size.$task-radius;
        }
    }
}

.disabled {
    background-color: color.$darkest !important;
    color: rgba(color.$light-text, 0.3);
    cursor: not-allowed !important;
}

.hidden {
    display: none;
}

.warn {
    color: color.$yellow;
}

.font--code {
    font-family: font.$code;
}
